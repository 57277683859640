// stores/authStore.js
import { defineStore } from 'pinia'
import { setCookie, getCookie, eraseCookie } from './cookies' // 調整路徑以匹配你的文件結構

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: getCookie('token') || ''
  }),
  actions: {
    setToken(token: string) {
      this.token = token
      setCookie('token', token, 7) // 存儲Token到Cookies，有效期7天
    },
    clearToken() {
      this.token = ''
      eraseCookie('token') // 移除Cookies中的Token
    },
    getToken() {
      return this.token
    },
    isLogin(): boolean {
      // 检查token是否存在且不为空
      return this.token !== ''
    }
  }
})
