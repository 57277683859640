<template>
  <div class="layout">
    <div class="navbar">
      <button @click="isMenuCollapsed = !isMenuCollapsed" class="menu-toggle">&#9776;</button>
      <div class="user-info popup-menu-switch" @click.stop="showUserMenu = !showUserMenu">
        {{ userName }} <span class="arrow">&#9660;</span>
        <div v-if="showUserMenu" class="popup-menu">
          <button @click="logout">登出</button>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="menu-wrapper" :class="{ collapsed: isMenuCollapsed }">
        <MainMenu />
      </div>
      <div class="content" ref="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import MainMenu from './MainMenu.vue' // 確保路徑正確
import { apiUserInfo } from '@/utils/serverAPI'
import { useAuthStore } from '@/utils/auth'
import router from '@/router'
const isMenuCollapsed = ref(false)
const showUserMenu = ref(false)
const userName = ref<string>('')

apiUserInfo().then((info) => {
  userName.value = (info['name'] as string) ?? ''
})
function logout() {
  // 這裡執行登出邏輯
  const authStore = useAuthStore()
  authStore.clearToken()
  router.push({ name: 'login' })
  // 可能需要重定向到登錄頁面或清除用戶狀態
  showUserMenu.value = false // 關閉用戶菜單
}

const closeMenu = (event: MouseEvent) => {
  // 这里使用了 event.target.closest 方法来检查点击事件是否发生在指定的类名上。
  // 如果点击发生在 '.user-info' 内部，closest 方法会返回非 null 的值，因此不关闭菜单。
  // 否则，如果点击发生在外部，就关闭菜单。
  const target = event.target as HTMLElement // 类型断言
  if (!target.closest('.user-info')) {
    showUserMenu.value = false
  }
}
// 添加事件监听器
onMounted(() => {
  document.addEventListener('click', closeMenu)
})

// 组件卸载时移除事件监听器
onUnmounted(() => {
  document.removeEventListener('click', closeMenu)
})
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 10px;
}

.menu-toggle,
.user-info {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
}

.user-info {
  align-items: center;
}

.arrow {
  margin-left: 5px;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden; /* 防止内容超出屏幕时溢出 */
}

.menu-wrapper {
  width: 200px;
  transition: width 0.3s ease;
  overflow: hidden;
  background-color: #262626;
}

.menu-wrapper.collapsed {
  width: 0;
}

.content {
  flex: 1;
  overflow-y: auto; /* 仅内容区域滚动 */
}
</style>
