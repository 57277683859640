<template>
  <div class="dialog">
    <div>
      <h3 class="dialog-title">{{ title }}</h3>
      <p class="dialog-message">{{ message }}</p>
    </div>
    <div class="dialog-actions">
      <button class="dialog-action mr-4" @click="onConfirm">{{ confirmText }}</button>
      <button class="dialog-action" @click="onCancel">{{ cancelText }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType } from 'vue'
// 為不同的對話框類型定義數據結構
const props = defineProps({
  title: String,
  message: String,
  cancelText: {
    type: String,
    default: '取消'
  },
  confirmText: {
    type: String,
    default: '確認'
  },
  onConfirm: {
    type: Function as PropType<() => void>,
    required: true
  },
  onCancel: {
    type: Function,
    default: undefined // 默認值為 undefined，表示 onCancel 是可選的
  }
})
const emit = defineEmits(['close'])

const onConfirm = () => {
  props.onConfirm()
  emit('close')
}

const onCancel = () => {
  props.onCancel?.()
  emit('close') // 使用可選鏈調用 onCancel，以防它未被定義
}
</script>

<style scoped></style>
