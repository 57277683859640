<template>
  <nav class="main-menu">
    <ul>
      <li
        v-for="item in menuItems"
        :key="item.name"
        :class="[{ 'active-route': item.highlight() }, 'my-0', 'py-2']"
      >
        <router-link :to="{ name: item.name }" class="block-link">
          <span class="icon"><font-awesome-icon :icon="item.icon" /></span>
          <span>{{ item.text }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const currentRouteName = computed(() => route.name)

const menuItems = [
  {
    name: 'settings',
    icon: 'tachometer-alt',
    text: '系統設定',
    highlight: () => currentRouteName.value === 'settings'
  },
  {
    name: 'playerList',
    icon: 'users',
    text: '使用者名單',
    highlight: () => route.path.startsWith('/player')
  },
  {
    name: 'blacklist',
    icon: 'user-slash',
    text: '黑名單',
    highlight: () => currentRouteName.value === 'blacklist'
  },
  {
    name: 'productList',
    icon: 'box-open',
    text: '商品名單',
    highlight: () => currentRouteName.value === 'productList'
  },
  {
    name: 'auctionList',
    icon: 'gavel',
    text: '競標品名單',
    highlight: () => route.path.startsWith('/auctionItem')
  },
  {
    name: 'storeItemList',
    icon: 'store',
    text: '商城商品名單',
    highlight: () => route.path.startsWith('/storeItem')
  },
  {
    name: 'iapList',
    icon: 'receipt',
    text: 'IAP項目',
    highlight: () => route.path.startsWith('/iap')
  },
  {
    name: 'winnerList',
    icon: 'trophy',
    text: '中獎清單',
    highlight: () => route.path.startsWith('/winner')
  },
  {
    name: 'usersList',
    icon: 'user-cog',
    text: '管理帳號資訊',
    highlight: () => route.path.startsWith('/user')
  }
]
</script>

<style>
nav {
  margin: 0px; /* 添加四周的內邊距 */
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: block;
  margin-bottom: 10px; /* 修改為從上到下的間距 */
}

nav li {
  display: flex;
  align-items: center; /* 圖標和文字垂直居中 */
  gap: 10px; /* 圖標和文字之間的間距 */
}

.main-menu a {
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  transition: 0.4s;
  padding: 3px;
}

.active-route {
  background-color: rgba(42, 179, 243, 0.907); /* 高亮背景色 */
}

.main-menu li:hover,
.active-route {
  background-color: rgba(42, 179, 243, 0.907); /* hover 和选中的高亮背景色 */
}

/* hover 效果现在应用于整个 li 包括高亮项 */
.main-menu a {
  margin-left: 20px;
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  transition: 0.4s;
  padding: 3px;
}
.block-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.icon {
  margin-right: 8px; /* 调整图标与文本的间距 */
}
</style>
