<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal">
      <component :is="currentDialogComponent" v-bind="dialogData" @close="closeDialog" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useDialogStore } from './../utils/dialogStore'
import ConfirmDialog from './ConfirmDialog.vue'
import MessageDialog from './MessageDialog.vue'

const dialogStore = useDialogStore()

// 計算當前應該顯示的組件
const currentDialogComponent = computed(() => {
  switch (dialogStore.type) {
    case 'confirm':
      return ConfirmDialog
    case 'message':
      return MessageDialog
    default:
      return null
  }
})

// 將 store 中的 data 傳遞給動態組件
const dialogData = computed(() => dialogStore.data)

const isVisible = computed(() => dialogStore.isVisible)

const closeDialog = () => {
  dialogStore.hideDialog()
}
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 確保在其他內容之上 */
}

.modal {
  /* 對話框的樣式 */
  z-index: 1001; /* 確保對話框在遮罩層之上 */
  /* 其他樣式 */
}
</style>
