import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainLayout from '../components/MainLayout.vue'
import { useAuthStore } from '@/utils/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: MainLayout, // 使用帶菜單的佈局

      children: [
        {
          path: '/', // 當路由為 / 時，加載 HomeView
          name: 'settings',
          component: () => import('../views/SettingView.vue')
        },
        // 可以在這裡添加更多子路由，它們也將顯示菜單
        {
          path: '/player',
          name: 'playerList',
          component: () => import('../views/Players/PlayersView.vue')
        },
        {
          path: '/player/:id',
          name: 'playerDetail',
          component: () => import('../views/Players/PlayerDetailView.vue'),
          props: true
        },
        {
          path: '/player/:id/items',
          name: 'playerItems',
          component: () => import('../views/Players/PlayerItemsView.vue'),
          props: true
        },
        {
          path: '/player/:id/transactions',
          name: 'playerTranscations',
          component: () => import('../views/Players/PlayerTransactionsView.vue'),
          props: true
        },
        {
          path: '/blacklist', // 當路由為 / 時，加載 HomeView
          name: 'blacklist',
          component: () => import('../views/BlackUsersView.vue')
        },
        // 可以在這裡添加更多子路由，它們也將顯示菜單
        {
          path: '/product',
          name: 'productList',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Products/ProductsView.vue')
        },
        {
          path: '/product/new',
          name: 'productCreate',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Products/ProductCreateView.vue')
        },
        {
          path: '/product/:id',
          name: 'productDetail',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Products/ProductDetailView.vue'),
          props: true
        },
        {
          path: '/product/createStoreItem/:id',
          name: 'storeItemCreate',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Products/StoreItemCreateView.vue')
        },
        {
          path: '/auctionItem', // 當路由為 / 時，加載 HomeView
          name: 'auctionList',
          component: () => import('../views/Auction/AuctionItemsView.vue')
        },
        {
          path: '/auctionItem/:id',
          name: 'auctionItemDetail',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Auction/AuctionItemDetailView.vue'),
          props: true
        },
        {
          path: '/product/createAuctionItem/:id',
          name: 'auctionItemCreate',
          component: () => import('../views/Products/AuctionItemCreateView.vue')
        },
        {
          path: '/auctionItem/:id/vouchers',
          name: 'voucherList',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Auction/VouchersView.vue'),
          props: true
        },
        // 可以在這裡添加更多子路由，它們也將顯示菜單
        {
          path: '/storeItem',
          name: 'storeItemList',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Store/StoreItemsView.vue')
        },

        {
          path: '/storeItem/:id',
          name: 'storeItemDetail',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Store/StoreItemDetailView.vue')
        },
        {
          path: '/storeItem/:id/transactions',
          name: 'storeItemTransactions',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Store/TransactionsView.vue')
        },
        {
          path: '/iap', // 當路由為 / 時，加載 HomeView
          name: 'iapList',
          component: () => import('../views/IAP/IAPView.vue')
        },
        {
          path: '/iap/new', // 當路由為 / 時，加載 HomeView
          name: 'iapItemCreate',
          component: () => import('../views/IAP/IAPCreateView.vue')
        },
        {
          path: '/iap/:id', // 當路由為 / 時，加載 HomeView
          name: 'iapItemDetail',
          component: () => import('../views/IAP/IAPDetailView.vue'),
          props: true
        },
        {
          path: '/winner',
          name: 'winnerList',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Winner/WinnersView.vue')
        },
        // 可以在這裡添加更多子路由，它們也將顯示菜單
        {
          path: '/user',
          name: 'usersList',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Users/UsersView.vue')
        },
        {
          path: '/user/new',
          name: 'createUser',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Users/UserCreateView.vue')
        },
        {
          path: '/user/:id',
          name: 'userDetail',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/Users/UserDetailView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView // 你的登入頁面組件
    }
  ]
})
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.isLogin()) {
    // 如果路由需要認證且用戶未登錄，重定向到登錄頁面
    next({ name: 'login' })
  } else if (authStore.isLogin() && to.matched.some((record) => record.name == 'login')) {
    next({ name: 'settings' })
  } else {
    // 否則正常跳轉
    next()
  }
})
export default router
