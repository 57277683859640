<template>
  <div class="login-container">
    <div class="login-box">
      <h1 class="login-title">樂購Admin</h1>
      <form @submit.prevent="handleLogin">
        <DataBox label="帳號:" name="username">
          <DataInput v-model="loginForm.username" name="username" type="text" required></DataInput>
        </DataBox>
        <DataBox label="密碼:" name="password">
          <DataInput
            v-model="loginForm.password"
            name="password"
            type="password"
            required
          ></DataInput>
        </DataBox>

        <button type="submit" class="login-button">登入</button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { apiLogin } from '@/utils/serverAPI'
import { useRouter } from 'vue-router'
import DataBox from '../components/DataBox.vue'
import DataInput from '../components/DataInput.vue'
import { useDialogStore } from '@/utils/dialogStore'
import { useLoadingStore } from '@/utils/loadingStore'
interface LoginForm {
  username: string
  password: string
}
const router = useRouter()
const loginForm = ref<LoginForm>({
  username: '',
  password: ''
})
const dialogStore = useDialogStore()
const loadingStore = useLoadingStore()
function handleLogin() {
  // 在這裡添加你的登入邏輯，例如發送HTTP請求到後端服務
  loadingStore.showLoading()
  apiLogin(loginForm.value.username, loginForm.value.password)
    .then((success) => {
      if (success) {
        router.push({ name: 'settings' })
      } else {
        dialogStore.showDialog({
          type: 'message',
          data: {
            title: '登入失敗',
            message: '帳號密碼錯誤!'
          }
        })
      }
    })
    .finally(() => {
      loadingStore.hideLoading()
    })
}
</script>
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-title {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}
</style>
