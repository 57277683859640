// dialogStore.ts
import { defineStore } from 'pinia'

type DialogType = 'confirm' | 'message'

interface MessageDialogData {
  title: string // 對話框的標題
  message: string
  closeText?: string // 可選，關閉按鈕文本，默認為 "確認"
  onConfirm?: () => void
}
export interface ConfirmDialogData {
  title: string // 對話框的標題
  message: string // 顯示的消息內容
  cancelText?: string // 取消按鈕的文本，默認值為"取消"
  confirmText?: string // 確認按鈕的文本，默認值為"確認"
  onConfirm: () => void // 點擊確認按鈕時的回調函數
  onCancel?: () => void // 點擊取消按鈕時的回調函數
}
// 使用類型別名和泛型定義 ShowDialogPayload
type ShowDialogPayload<T> = T extends 'confirm'
  ? { type: T; data: ConfirmDialogData }
  : T extends 'message'
    ? { type: T; data: MessageDialogData }
    : never
// 定義狀態和動作的類型
interface DialogState {
  isVisible: boolean
  data: ConfirmDialogData | MessageDialogData | null
  type: DialogType | null
}

export const useDialogStore = defineStore('dialog', {
  state: (): DialogState => ({
    isVisible: false,
    data: null,
    type: null
  }),
  actions: {
    showDialog<T extends DialogType>(payload: ShowDialogPayload<T>) {
      this.isVisible = true
      this.type = payload.type
      this.data = payload.data
    },
    hideDialog() {
      this.isVisible = false
      this.data = null
      this.type = null
    }
  }
})
