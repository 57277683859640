// ServerAPI.ts

import { useAuthStore } from '@/utils/auth'
import router from '@/router'
type APIParams = Record<string, any>

enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT'
}
interface PaginationData {
  currentPage: number
  totalPages: number
}
interface PageData {
  items: Array<Record<string, any>>
  pagination: PaginationData
}
class ResponseException extends Error {
  statusCode: number
  errorCode: number

  constructor(statusCode: number, message: string, errorCode: number) {
    super(message)
    this.statusCode = statusCode
    this.errorCode = errorCode
    this.name = 'ResponseException'
  }
}

async function callApi<T>(api: string, method: RequestMethod, params: APIParams = {}): Promise<T> {
  const baseUrl = import.meta.env.VITE_SERVER_URL // 從環境變量讀取基礎URL
  let url = `${baseUrl}/${api}`

  const options: RequestInit = {
    method: method
  }
  const authStore = useAuthStore()

  const token = authStore.getToken()
  const headers: Record<string, string> = {}
  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }
  options.headers = headers
  if (method === RequestMethod.GET) {
    // 處理GET請求的查詢參數
    const queryParams = new URLSearchParams(params).toString()
    url += `?${queryParams}`
  } else {
    // 處理POST、PUT、DELETE請求的body數據
    const formData = new FormData()
    for (const key in params) {
      formData.append(key, params[key])
    }
    if (method == RequestMethod.PUT) {
      formData.append('_method', 'PUT')
    } else if (method == RequestMethod.DELETE) {
      formData.append('_method', 'DELETE')
    }
    options.method = RequestMethod.POST
    options.body = formData
  }

  try {
    const response = await fetch(url, options)
    // 使用 handleResponse 处理响应
    return await handleResponse<T>(response)
  } catch (error) {
    if (error instanceof ResponseException && error.errorCode === -100) {
      // 特定错误码处理，例如：doLogout();
      const authStore = useAuthStore()
      authStore.clearToken()
      router.push({ name: 'login' })
    }
    throw error // 抛出其他错误，供调用者处理
  }
}
async function callApiGetDetail(api: string, params: APIParams = {}): Promise<Record<string, any>> {
  return callApi(api, RequestMethod.GET, params)
    .then((data) => {
      return data as Record<string, any>
    })
    .catch(() => {
      return {}
    })
}
async function callApiPutUpdate(api: string, params: APIParams = {}): Promise<boolean> {
  return callApi(api, RequestMethod.PUT, params)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}
async function callApiPostCreate(api: string, params: APIParams = {}): Promise<number> {
  return callApi(api, RequestMethod.POST, params)
    .then((data) => {
      return ((data as Record<string, any>)['id'] as number) ?? 0
    })
    .catch(() => {
      return 0
    })
}
async function callApiDelete(api: string, params: APIParams = {}): Promise<boolean> {
  return callApi(api, RequestMethod.DELETE, params)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}
async function callApiPage(
  api: string,
  page: number,
  search: string = '',
  params: APIParams = {}
): Promise<PageData> {
  params['page'] = page
  if (search.length > 0) {
    params['search'] = search
  }
  return callApi(api, RequestMethod.GET, params)
    .then((data) => {
      const responseData = data as Record<string, any>
      const items = responseData['items'] as Array<Record<string, any>>
      const pagination = responseData['pagination'] as Record<string, any>
      return {
        items: items,
        pagination: {
          currentPage: pagination.currentPage,
          totalPages: pagination.totalPages
        }
      }
    })
    .catch((e) => {
      return {
        items: [],
        pagination: {
          currentPage: 1,
          totalPages: 1
        }
      }
    })
}
async function handleResponse<T>(response: Response): Promise<T> {
  if (response.status === 200) {
    const result = (await response.json()) as any // 使用 any 类型作为过渡
    const success = result.success

    if (success) {
      const data: T = result.data
      return data
    } else {
      const error: { code?: number; message?: string } = result.error || {}
      const errorCode: number = error.code || 0

      throw new ResponseException(response.status, error.message || 'Unknown error', errorCode)
    }
  } else {
    // 对于非200状态码的处理
    throw new ResponseException(response.status, 'UNKNOWN', 0)
  }
}

export async function apiLogin(account: string, password: string): Promise<boolean> {
  return callApi('login', RequestMethod.POST, { account: account, password: password })
    .then((data) => {
      const accessToken = (data as Record<string, string>)['token']
      const authStore = useAuthStore()
      authStore.setToken(accessToken)

      return true
    })
    .catch(() => {
      return false
    })
}

export async function apiUserInfo(): Promise<Record<string, string>> {
  return callApiGetDetail('info')
}

export async function apiGetSettings(): Promise<Record<string, any>> {
  return callApiGetDetail('settings')
}

export async function apiMakeDraw(): Promise<boolean> {
  return callApi('draw', RequestMethod.GET)
    .then(() => {
      return true
    })
    .catch((error) => {
      return false
    })
}

export async function apiUpdateSettings(
  goldDiamondExchangeRate: number,
  priceToGoldRate: number,
  voucherReturnCoinRate: number,
  friendReturnRate: number,
  agreement: string
): Promise<boolean> {
  return callApi('settings', RequestMethod.PUT, {
    goldDiamondExchangeRate: goldDiamondExchangeRate,
    priceToGoldRate: priceToGoldRate,
    voucherReturnCoinRate: voucherReturnCoinRate,
    friendReturnRate: friendReturnRate,
    agreement: agreement
  }).then(() => {
    return true
  })
}

export async function apiGetPlayers(page: number, search: string = ''): Promise<PageData> {
  return callApiPage('players', page, search)
}
export async function apiGetBlockPlayers(page: number, search: string = ''): Promise<PageData> {
  return callApiPage('blockPlayers', page, search)
}
export async function apiGetPlayerInfo(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('playerInfo/' + id)
}
export async function apiGetPlayerDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('player/' + id)
}
export async function apiUpdatePlayerDetail(
  id: number,
  coins: number,
  golds: number,
  diamonds: number
): Promise<boolean> {
  return callApiPutUpdate('player/' + id, {
    coins: coins,
    golds: golds,
    diamonds: diamonds
  })
}

export async function apiBlockPlayer(id: number, block: boolean): Promise<boolean> {
  return callApiPutUpdate('player/' + id, {
    enable: block ? '0' : '1'
  })
}

export async function apiGetPlayerItems(playerId: number, page: number): Promise<PageData> {
  return callApiPage('playerItems/' + playerId, page)
}

export async function apiGetPlayerTransactions(playerId: number, page: number): Promise<PageData> {
  return callApiPage('playerTransactions/' + playerId, page)
}

export async function apiGetProducts(page: number, search: string): Promise<PageData> {
  return callApiPage('products', page, search)
}

export async function apiGetProductDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('product/' + id)
}

export async function apiCreateProduct(
  name: string,
  price: number,
  description: string
): Promise<number> {
  return callApiPostCreate('product', {
    name: name,
    price: price,
    description: description
  })
}

export async function apiUpdateProductDetail(
  id: number,
  name: string,
  price: number,
  description: string
): Promise<boolean> {
  return callApiPutUpdate('product/' + id, {
    name: name,
    price: price,
    description: description
  })
}
export async function apiDeleteProductDetailImage(id: number): Promise<boolean> {
  return callApiDelete('productImage/' + id)
}

export async function apiCreateProductDetailImage(
  id: number,
  file: File
): Promise<Record<string, any> | null> {
  return callApi('productImage/' + id, RequestMethod.POST, { image: file })
    .then((data) => {
      return data as Record<string, any>
    })
    .catch(() => {
      return null
    })
}

export async function apiGetStoreItems(page: number, search: string): Promise<PageData> {
  return callApiPage('storeItems', page, search)
}
export async function apiUpdateStoreItemOnStock(id: number, onStock: boolean): Promise<boolean> {
  return callApiPutUpdate('storeItem/' + id, { onStock: onStock ? 1 : 0 })
}
export async function apiUpdateStoreItem(
  id: number,
  diamonds: number,
  coins: number
): Promise<boolean> {
  return callApiPutUpdate('storeItem/' + id, {
    diamonds: diamonds,
    coins: coins
  })
}
export async function apiCreateStoreItem(
  productId: number,
  diamonds: number,
  coins: number
): Promise<number> {
  return callApiPostCreate('storeItem', {
    productId: productId,
    diamonds: diamonds,
    coins: coins
  })
}
export async function apiGetStoreItemDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('storeItem/' + id)
}

export async function apiGetStoreItemInfo(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('storeItemInfo/' + id)
}
export async function apiGetStoreItemTransactions(id: number, page: number): Promise<PageData> {
  return callApiPage('storeItemTransactions/' + id, page)
}

export async function apiGetAuctionItems(page: number, search: string): Promise<PageData> {
  return callApiPage('auctionItems', page, search)
}
export async function apiUpdateAuctionItemOnStock(id: number, onStock: boolean): Promise<boolean> {
  return callApiPutUpdate('auctionItem/' + id, { onStock: onStock ? 1 : 0 })
}
export async function apiCreateAuctionItem(
  productId: number,
  cost: number,
  endTime: Date
): Promise<number> {
  return callApiPostCreate('auctionItem', {
    productId: productId,
    cost: cost,
    endTime: endTime.toISOString()
  })
}
export async function apiUpdateAuctionItem(
  id: number,
  cost: number,
  endTime: Date
): Promise<boolean> {
  return callApiPutUpdate('auctionItem/' + id, {
    cost: cost,
    endTime: endTime.toISOString()
  })
}
export async function apiGetAuctionItemDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('auctionItem/' + id)
}

export async function apiGetAuctionItemInfo(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('auctionItemInfo/' + id)
}
export async function apiGetAuctionItemVouchers(id: number, page: number): Promise<PageData> {
  return callApiPage('auctionItemVouchers/' + id, page)
}

export async function apiGetUsers(page: number, search: string): Promise<PageData> {
  return callApiPage('users', page, search)
}
export async function apiGetUserDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('user/' + id)
}
export async function apiDeleteUser(id: number): Promise<boolean> {
  return callApiDelete('user/' + id)
}
export async function apiCreateUser(name: string, password: string): Promise<number> {
  return callApiPostCreate('user', { name: name, password: password })
}
export async function apiUpdateUser(id: number, password: string): Promise<boolean> {
  return callApiPutUpdate('user/' + id, { password: password })
}

export async function apiGetIAPItems(page: number, search: string): Promise<PageData> {
  return callApiPage('iapItems', page, search)
}
export async function apiGetIAPItemDetail(id: number): Promise<Record<string, any>> {
  return callApiGetDetail('iapItem/' + id)
}
export async function apiDeleteIAPItem(id: number): Promise<boolean> {
  return callApiDelete('iapItem/' + id)
}
export async function apiCreateIAPItem(
  appleProductId: string,
  googleProductId: string,
  diamonds: number
): Promise<number> {
  const params: APIParams = { diamonds: diamonds }
  params['appleProductId'] = appleProductId
  params['googleProductId'] = googleProductId
  return callApiPostCreate('iapItem', params)
}
export async function apiUpdateIAPItem(
  id: number,
  appleProductId: string,
  googleProductId: string,
  diamonds: number
): Promise<boolean> {
  const params: APIParams = { diamonds: diamonds }
  params['appleProductId'] = appleProductId
  params['googleProductId'] = googleProductId
  return callApiPutUpdate('iapItem/' + id, params)
}
export async function apiUpdateIAPItemEnable(id: number, enable: boolean): Promise<boolean> {
  return callApiPutUpdate('iapItem/' + id, { enable: enable ? '1' : '0' })
}
export async function apiGetWinners(page: number, search: string): Promise<PageData> {
  return callApiPage('winners', page, search)
}
