<template>
  <input
    ref="textInput"
    v-bind="$attrs"
    :class="[
      'shrink-0 rounded-normal border border-grey px-3 py-2.5 leading-144 text-blue',
      'placeholder:text-secgrey',
      'text-gray-800',
      // 'disabled:bg-background disabled:text-secgrey',
      errorMessage ? 'border-error' : ' '
    ]"
    :value="modelValue"
    @input="$emit('update:modelValue', ($event.target as HTMLInputElement)!.value)"
  />
  <span v-if="unit" class="ml-2">
    {{ unit }}
  </span>
</template>

<script setup lang="ts">
import { defineExpose, ref } from 'vue'
interface Props {
  unit?: string
  errorMessage?: string
  disableErrorMessage?: boolean
  modelValue: string | number
}

defineProps<Props>()
defineEmits(['update:modelValue'])

const textInput = ref<HTMLInputElement | null>(null)
// 將 handleFocus 暴露出來
defineExpose({ textInput })
</script>
