<template>
  <div class="dialog">
    <div>
      <h3 class="dialog-title">{{ title }}</h3>
      <p class="dialog-message">{{ message }}</p>
    </div>
    <div class="dialog-actions">
      <!-- 根据 onConfirm 是否被提供来决定显示哪个按钮 -->
      <button class="dialog-action" v-if="onConfirm" @click="onConfirmClick">
        {{ closeText }}
      </button>
      <button class="dialog-action" v-else @click="closeDialog">{{ closeText }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    message: string
    closeText?: string
    onConfirm?: () => void // 明确标记为可选
  }>(),
  {
    closeText: '確認', // 提供默认值
    onConfirm: undefined // 可选的回调函数，默认为 undefined
  }
)

const emit = defineEmits(['close'])
const onConfirmClick = () => {
  emit('close')
  if (props.onConfirm != undefined) {
    props.onConfirm()
  }
}
const closeDialog = () => {
  emit('close')
}
</script>

<style scoped>
h3 {
  margin-top: 0;
}
</style>
